import { template as template_a6f439c77e004db2a464b99e55528155 } from "@ember/template-compiler";
const FKText = template_a6f439c77e004db2a464b99e55528155(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
