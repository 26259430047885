import { template as template_2f9a5f4d035e42e2a96d54c1e120b5ec } from "@ember/template-compiler";
const FKControlMenuContainer = template_2f9a5f4d035e42e2a96d54c1e120b5ec(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
