import { template as template_b88e4e96cefc460386603c7a1ad06703 } from "@ember/template-compiler";
const FKLabel = template_b88e4e96cefc460386603c7a1ad06703(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
